<template>
  <div class="shop">
    <!-- 搜索 -->
    <van-sticky :offset-top="0">
      <div>
        <!-- <van-search
          v-model="search"
          placeholder="请输入队伍名称"
          @search="onSearch"
        >
          <template #left-icon>
            <img
              src="https://lyxnyapi.dexian.ren//upload/images/2022/7/cfe5e07a-158.png"
              alt=""
              width="14"
              height="14"
            />
          </template>
        </van-search> -->
      </div>
      <!-- <van-tabs
        @click="onClickOnlyOne"
        color="#f63e3b"
        title-active-color="#f63e3b"
      >
        <van-tab name="全部" title="全部"> </van-tab>
        <van-tab
          v-for="item in CateList"
          :key="item.CateId"
          :name="item.CateId"
          :title="item.Name"
        >
        </van-tab>
      </van-tabs> -->
    </van-sticky>
    <!-- tab栏 -->
    <div class="information">
      <!-- 全部 -->
      <van-list
        v-model="loading"
        :finished="finished"
        offset="50"
        @load="onLoad"
        :immediate-check="false"
      >
        <div class="center" v-for="item in list" :key="item.index">
          <router-link :to="'/shop/helpXQ/' + item.ResortId">
            <van-card style="padding: 12px; background-color: white">
              <template #title>
                <div
                  class="centerTitle van-ellipsis--l1"
                  style="width: 75%; padding-bottom: 6px"
                >
                  求助人：{{ item.Name }}
                </div>
              </template>

              <template #tags>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 65%;
                  "
                >
                  <div
                    style="
                      padding-bottom: 2px;
                      font-size: 13px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #999999;
                    "
                  >
                    <!-- <van-icon
                      name="http://alycommunity.anluoyun.cn/mj-jljd/2023/11/cce14aa0-b30.png"
                    /> -->
                    求助时间：{{ item.ResortTime | Time }}
                  </div>
                  <div
                    style="
                      padding-bottom: 2px;
                      font-size: 13px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #999999;
                    "
                  >
                    <!-- <van-icon
                      name="http://alycommunity.anluoyun.cn/mj-jljd/2023/11/e21aac96-379.png"
                    /> -->
                    求助地点：{{ item.Address }}
                  </div>
                  <div
                    style="
                      font-size: 13px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #999999;
                    "
                  >
                    <!-- <van-icon
                      name="http://alycommunity.anluoyun.cn/mj-jljd/2023/11/591913d2-404.png"
                    /> -->
                    联系电话：{{ item.TouchTel }}
                  </div>
                </div>
                <!-- <div
                  class="van-multi-ellipsis--l2"
                  v-if="item.Address != ''"
                  style="width: 75%; padding-bottom: 5px; font-size: 13px"
                >
                  <van-icon name="location" /> {{ item.Address }}
                </div> -->
                <!-- <div class="county"
                @click="gotominiProgram(item)">
                <img src="https://xczxapi.zhsq.cloud//upload/images/2022/6/b10693b0-c37.png"
                  alt=""
                  style="width: 24px;">
              </div> -->
              </template>
              <template #footer>
                <span v-if="item.Status == 1" class="state">{{
                  item.StatusDesc
                }}</span>
                <span v-if="item.Status == 2" class="state2">{{
                  item.StatusDesc
                }}</span>
                <span v-if="item.Status == 3" class="state3">{{
                  item.StatusDesc
                }}</span>
              </template>
            </van-card>
          </router-link>
        </div>
      </van-list>
      <!-- 没有更多数据了 -->
      <van-empty description="暂无数据" v-if="!list.length" />
    </div>
  </div>
</template>
<script>
import { GetResortPage, WxGetProjectCateList } from "@/api/RealInfo";
import { getwgToken } from "@/utils/auth";
export default {
  data() {
    return {
      CateList: [],
      riKind: "", //tab栏
      list: [], //资讯列表
      listfrom: {
        page: 1,
        limit: 10,
      }, //查询传参
      search: "", //搜索内容
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
    };
  },

  filters: {
    Time(val) {
      console.log(val);
      return val.replace("T", " ");
    },
  },
  methods: {
    // 获取资讯分类
    async getInfoKindInfoPage() {
      const res = await WxGetProjectCateList();
      console.log(res, "res");
      this.CateList = res.data.data;
      console.log(this.CateList, "this.CateList");
    },
    onClickOnlyOne(name, title) {
      console.log(name);
      this.riKind = name;
      this.listfrom.page = 1;
      this.getList();
    },
    // 获取分页列表
    getList() {
      // this.listfrom.cateId = this.riKind;
      this.listfrom.name = this.search;
      this.listfrom.accToken = getwgToken();
      GetResortPage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.finished = true;
              this.list = {};
            } else {
              this.list = res.data.data;
              // this.list = {};
              this.total = res.data.count;
              this.finished = true;
              this.hidden = true;
              for (var i = 0; i < this.list.length; i++) {
                var item = this.list[i].IssTime;
                item = this.dataFormat(item);
                this.list[i].IssTime = item;
                // if (this.list[i].ShopImgUrl != "rejected") {
                //   this.list[i].ShopImgUrl =
                //     "https://lyxnyapi.dexian.ren//upload/images/2022/7/b93cef0f-689.png";
                // }
              }
            }
          }
        })
        .catch(() => {});
    },
    // 下拉刷新
    onLoad() {
      console.log(123);
      this.listfrom.page = this.listfrom.page + 1;
      this.getList();
    },
    // tan点击切换事件
    // onClick(e) {
    //   this.riKind = e;
    //   this.listfrom.page = 1;
    //   this.getList();
    // },
    // 搜索
    onSearch() {
      this.listfrom.page = 1;
      this.getList();
    },
    // 时间格式化
    dataFormat(value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
  mounted() {
    this.getList();
    // this.getInfoKindInfoPage();
  },
};
</script>
<style scoped>
.shop {
  background: #f8f8f8;
  min-height: 100vh;
}
.van-card__thumb {
  margin-right: 11px;
  width: 85px;
  height: 85px;
}
.shopTitle {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  padding: 10px;
}
.shop .information .center {
  border-bottom: none;
  padding: 0px;
  border-radius: 5px;
}
.shop .center {
  width: 92%;
  margin: 10px auto;
  /* box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%); */
  /* background: rgb(246, 243, 243); */
}
.centertime {
  font-size: 14px !important;
}
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
/deep/.van-field__left-icon {
  margin-right: 6px;
  margin-top: 2px;
}

/deep/ .van-card {
  border-radius: 8px;
}

.centerTitle {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #1b2024;
  padding-top: 4px;
}

/deep/ .van-icon__image {
  width: 1.2em;
  height: 1.2em;
}

/deep/ .van-icon {
  vertical-align: middle;
  margin-right: 6px;
}

/deep/ .van-card__footer {
  position: absolute;
  right: 0px;
  top: 11%;
  transform: translateY(-50%);
}

/deep/ .van-button {
  height: 24px;
  padding: 0 8px;
  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}

/deep/ .van-button--default {
  border: 1px solid #dcdcdc !important;
  border-radius: 12px !important;
}
.essay {
  padding-bottom: 50px;
}
.state {
  background: #ecf5ff;
  color: #409eff;
  padding: 6px 8px;
  border-radius: 0 8px;
  font-size: 12px;
}
.state2 {
  background: #fdf6ec;
  color: #e6a23c;
  padding: 6px 8px;
  border-radius: 0 8px;
  font-size: 12px;
}
.state3 {
  background: #f0f9eb;
  color: #67c23a;
  padding: 6px 8px;
  border-radius: 0 8px;
  font-size: 12px;
}
</style>